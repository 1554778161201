<template>
    <!-- 学会通知 学会动态 科技进展 学会负责人 联系我们内容-->
    <div class="page_content">
        <div class="page_f">
            {{title}}
        </div>
        <div class="page_r">
            <div class="page_title">首页 > <span>{{title}}</span></div>
            <div v-if="title == '联系我们'" class="contact">
                <div class="contact_f">
                    <div>
                        <div class="icon">
                            <img src="../assets/image/contact/phone.png" alt="">
                        </div>
                        <div>
                            <div class="title">电话</div>
                            <div>0311-81668672</div>
                        </div>
                    </div>
                    <div>
                        <div class="icon">
                            <img src="../assets/image/contact/internet.png" alt="">
                        </div>
                        <div>
                            <div class="title">网址</div>
                            <div>http://hebams.cn/</div>
                        </div>
                    </div>
                    <div>
                        <div class="icon">
                            <img src="../assets/image/contact/email.png" alt="">
                        </div>
                        <div>
                            <div class="title">邮编</div>
                            <div>050018</div>
                        </div>
                    </div>
                    <div>
                        <div class="icon">
                            <img src="../assets/image/contact/address.png" alt="">
                        </div>
                        <div>
                            <div class="title">地址</div>
                            <div>河北省石家庄市裕华区裕翔街26号</div>
                        </div>
                    </div>
                </div>
                <div class="contact_r"></div>
            </div>
            <div v-else-if="title == '学会负责人'" class="people">
                <div class="people_list">
                    <div v-for="item in list" :key="item.id" @click="jump(item.id)">
                        <div class="people_img"> 
                            <img :src="item.avatar" alt="">
                        </div>
                        <div class="people_text">
                            <div class="name">
                                <span>{{item.name}}</span>
                                <router-link :to="`/people/${item.id}`">
                                    <img src="../assets/image/homePage/more.png" alt="">
                                </router-link>
                            </div>
                            <div class="achi" v-html="item.achievement">
                                <!-- {{ item.achievement }} -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list_page">
                    <el-pagination
                    @current-change="handleCurrentChange1"
                    layout="prev, pager, next"
                    :pager-count="5"
                    :page-size="6"
                    :total="total">
                    </el-pagination>
                </div>
            </div>
            <div v-else-if="title == '消息通知' || title == '搜索结果'" class="info">
                <div class="info_list">
                    <listItem v-for="item in list" :key="item.id" :list="item" title="info"/>
                </div>
                <div class="list_page">
                    <el-pagination
                    @current-change="handleCurrentChange1"
                    layout="prev, pager, next"
                    :pager-count="5"
                    :total="total">
                    </el-pagination>
                </div>
            </div>
            <div v-else>
                <div class="page_list">
                    <div v-for="item in list" :key="item.id" @click="to(item.id)">
                        <div class="list_text">
                            <div class="list_title">
                                <router-link :to="toAdress + item.id">{{item.title}}</router-link> 
                            </div>
                            <div class="list_cont">
                                {{item.abstract}}
                            </div>
                            <div class="list_bot">
                                <span>{{item.publish_date}}</span>
                                <span class="view"><i></i><span>{{item.view_count}}</span></span>
                            </div>
                        </div>
                        <div class="list_img">
                            <img :src="item.thumb" alt="">
                        </div>
                    </div>
                </div>
                <div class="list_page">
                    <el-pagination
                    @current-change="handleCurrentChange1"
                    layout="prev, pager, next"
                    :pager-count="5"
                    :page-size="5"
                    :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import listItem from './listItem.vue'
    export default {
        props:['title','list','total'],
        components:{
            listItem
        },
        computed:{
            toAdress(){
                if(this.title == '学会动态'){
                    return '/action/'
                }
                if(this.title == '科技进展'){
                    return '/progress/'
                }
                if(this.title == '学会通知'){
                    return '/notice/'
                }
            }
        },
        methods:{
            // 学会通知 学会动态 科技进展 点击分页触发
            handleCurrentChange1(val){
                this.$emit('getArticleList',val)
            },
            to(id){
                this.$router.push(`${this.toAdress}${id}`)
            },
            jump(id){
                this.$router.push('/people/' + id)
            }
        }
    }
</script>

<style scoped lang='less'>
.page_content{
    display: flex;
    justify-content: space-between;
    width: 1140px;
    min-width: 1140px;
    margin: 20px auto 30px;
    .page_f{
        width: 270px;
        height: 52px;
        font-size: 16px;
        line-height: 52px;
        color: #fff;
        padding-left: 27px;
        background-color: #1D7FD7;
    }
    .page_r{
        width: 844px;
        //联系我们样式
        .contact{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            .contact_f{
                >div{
                    display: flex;
                    font-size: 16px;
                    color: #666666;
                    line-height: 16px;
                    margin-bottom: 26px;
                    .icon{
                        width: 28px;
                        height: 28px;
                        margin-right: 15px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .title{
                        font-size: 18px;
                        line-height: 18px;
                        color: #333333;
                        margin-bottom: 14px;
                        +div{
                            width: 148px;
                        }
                    }
                }
            }
            .contact_r{
                width: 572px;
                height: 318px;
                // border: 1px solid #818382;
                background-image: url('../assets/image/contact/map.png');
            }
        }
        //联系我们样式结束
        //学会负责人样式
        .people{
            .people_list{
                margin-top: 26px;
                padding-bottom: 44px;
                border-bottom: 2px dotted #fff6f6;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                >div{
                    display: flex;
                    width: 389px;
                    // height: 110px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    .people_img{
                        flex-shrink: 0;
                        width: 140px;
                        height: 175px;
                        margin-right: 24px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .people_text{
                        .name{
                            display: flex;
                            justify-content: space-between;
                            font-size: 18px;
                            color: #000000;
                            margin-bottom: 18px;
                        }
                        .achi{
                            // margin-top: 20px;
                            // font-size: 16px;
                            // line-height: 16px;
                            color: #272727;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 6;
                            overflow: hidden;
                            
                        }
                    }
                }
            }
        }
        //学会负责人样式结束
        //消息通知样式
        .info{
            .info_list{
                min-height: 435px;
                border-bottom: 2px dotted #D2D2D2;
            }
        }
        //消息通知样式结束
        .page_title{
            font-size: 16px;
            padding-bottom: 8px;
            border-bottom: 2px solid #1D7FD7;
            span{
                color: #1D7FD7;
            }
        }
        .page_list{
            margin-top: 26px;
            min-height: 844px;
            padding-bottom: 9px;
            border-bottom: 2px dotted #D2D2D2;
            >div{
                margin-bottom: 30px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                .list_text{
                    width: 600px;
                    // margin-right: 39px;
                    .list_title{
                        height: 55px;
                        font-size: 18px;
                        line-height: 30px;
                        margin-top: -5px;
                        display: -webkit-box;
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:2;
                        overflow: hidden;
                        margin-bottom: 12px;
                        a{
                            color: #333333;
                        }
                    }
                    .list_cont{
                        font-size: 16px;
                        line-height: 26px;
                        color: #999999;
                        display: -webkit-box;
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:2;
                        overflow: hidden;
                    }
                    .list_bot{
                        font-size: 14px;
                        color: #999999;
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        line-height: 0;
                        .view{
                            font-size: 16px;
                            display: flex;
                            align-items: center;
                            i{
                                display: inline-block;
                                width: 22px;
                                height: 16px;
                                margin-left: 14px;
                                margin-right: 8px;
                                background-image: url('../assets/image/notice/矢量智能对象.png');
                            }
                        }
                    }
                }
                .list_img{
                    width: 210px;
                    height: 155px;
                    border-radius: 10px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .list_page{
            margin-top: 12px;
            display: flex;
            justify-content: center;
            /deep/.el-pager li{
                width: 40px;
                font-size: 14px;
                color: #999999;
                height: 42px;
                line-height: 42px;
                margin-right: 20px;
            }
            /deep/.el-pager li.active{
                border-bottom: 3px solid #237FC8;
                color: #000000;
            }
            /deep/.el-pagination button{
                height: 42px;
            }
            /deep/.el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon{
                font-size: 18px;
            }
            /deep/.el-pagination .btn-next, .el-pagination .btn-prev{
                color:#777777;
            }
            /deep/.el-pagination .btn-prev{
                margin-right: 20px;
            }
        }
    }
}
</style>