<template>
<!-- 首页中list 消息通知-->
    <div class="list_item" :class="this.title == 'info' ? 'info' : ''">
        <div class="diamond"></div>
        <template v-if="list.notice_id">
            <router-link :to="`/${this.title}/${this.list.notice_id}`">{{list.notice.title}}</router-link>
            <span>{{list.time}}</span>
        </template>
        <template v-else>
            <router-link :to="`/${this.toAdress}/${this.list.id}`">{{list.title}}</router-link>
            <span>{{list.publish_date}}</span>
        </template>
    </div>
</template>

<script>
    export default {
        props:['list','title'],
        computed:{
            toAdress(){
                return this.list.type ? this.list.type == 'dynamic' ? 'action' : this.list.type : this.title
            }
        }
    }
</script>

<style scoped lang='less'>
.list_item{
    // width: 571px;
    display: flex;
    align-items: center;
    .diamond{
        width: 6px;
        height: 6px;
        background-color: #FF5B06;
        transform: rotate(45deg);
    }
    a{
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 440px;
        margin-left: 10px;
        font-size: 14px;
        color: #333333;
        margin-right: 32px;
        &:hover{
            color: red;
        }
    }
    span{
        font-size: 14px;
        color: #999999;
    }
}
.info{
    margin-top: 22px;
    .diamond{
        width: 12px;
        height: 12px;
        background-image: url('../assets/image/info/lingxing.png');
        background-color: #FFFFFF;
        transform: rotate(0deg);
    }
    a{
        width: 712px;
        font-size: 16px;
    }
}
</style>